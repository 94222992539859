// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cards {
  padding: 0rem 2rem 2rem 2rem;
  animation: home-background 11s ease 0s 1;
}

.cards-dark {
  padding: 0rem 2rem 2rem 2rem;
  animation: home-background 11s ease 0s 1;
}

.cards > h1, .cards-dark > h1 {
  text-align: left;
  margin-left: 60px; 
  animation: text 11s ease 0s 1;
}

.cards-dark > h1 {
  color: #D3D3D3;
  animation: text-dark 11s ease 0s 1;
}

.cards-container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
}

.cards-wrapper {
  position: relative;
  margin: 50px 0 45px;
  width: 100%;
}

.cards-items {
  margin-bottom: 24px;
}

@media only screen and (min-width: 1024px) {
  .cards-items {
    display: flex;
  }
}

@media only screen and (max-width: 1024px) {
  .cards-items {
    margin-bottom: 2rem;
  }
}

@media only screen and (max-width: 500px) {
  .cards > h1, .cards-dark > h1 {
    margin: 0vmin 1vmin 1vmin 1vmin;
    font-size: 8vmin;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/Projects.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;EAC5B,wCAAwC;AAC1C;;AAEA;EACE,4BAA4B;EAC5B,wCAAwC;AAC1C;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,6BAA6B;AAC/B;;AAEA;EACE,cAAc;EACd,kCAAkC;AACpC;;AAEA;EACE,aAAa;EACb,iBAAiB;EACjB,mBAAmB;EACnB,iBAAiB;EACjB,UAAU;EACV,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE;IACE,aAAa;EACf;AACF;;AAEA;EACE;IACE,mBAAmB;EACrB;AACF;;AAEA;EACE;IACE,+BAA+B;IAC/B,gBAAgB;EAClB;AACF","sourcesContent":[".cards {\n  padding: 0rem 2rem 2rem 2rem;\n  animation: home-background 11s ease 0s 1;\n}\n\n.cards-dark {\n  padding: 0rem 2rem 2rem 2rem;\n  animation: home-background 11s ease 0s 1;\n}\n\n.cards > h1, .cards-dark > h1 {\n  text-align: left;\n  margin-left: 60px; \n  animation: text 11s ease 0s 1;\n}\n\n.cards-dark > h1 {\n  color: #D3D3D3;\n  animation: text-dark 11s ease 0s 1;\n}\n\n.cards-container {\n  display: flex;\n  flex-flow: column;\n  align-items: center;\n  max-width: 1120px;\n  width: 90%;\n  margin: 0 auto;\n}\n\n.cards-wrapper {\n  position: relative;\n  margin: 50px 0 45px;\n  width: 100%;\n}\n\n.cards-items {\n  margin-bottom: 24px;\n}\n\n@media only screen and (min-width: 1024px) {\n  .cards-items {\n    display: flex;\n  }\n}\n\n@media only screen and (max-width: 1024px) {\n  .cards-items {\n    margin-bottom: 2rem;\n  }\n}\n\n@media only screen and (max-width: 500px) {\n  .cards > h1, .cards-dark > h1 {\n    margin: 0vmin 1vmin 1vmin 1vmin;\n    font-size: 8vmin;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
